.competition-container {
  position: relative;
  width: 90%;
  max-width: 850px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  margin: 0 auto;
  margin-bottom: 120px;
  padding-bottom: 120px;
  background-color: #fff;
  border-radius: 4px;
  min-height: 1200px;

  h1 {
    margin-top: 60px;
    margin-bottom: 0px;
    font-size: 48px;
    @media (max-width: 900px) {
      font-size: 28px;
    }
  }

  span {
    margin-top: 10px;
    font-size: 28px;
    @media (max-width: 900px) {
      font-size: 20px;
    }
  }

  ul.prize-list {
    list-style-type: none;
    padding: 0;
    text-align: left;
  
    li {
      padding: 5px 0;
  
      &:last-child {
        border-bottom: none; 
      }
  
      span {
        margin-right: 5px;
        font-size: 22px;
      }
  
      b {
        color: #0090F2;
        font-size: 22px;
      }

      @media (max-width: 900px) {
        span, b {
          font-size: 16px;
        }
      }
    }
  }

  .status {
    position: relative;
    border: 1px solid #666;
    background-color: #fff;
    padding: 5px 10px;
    text-align: center;
    font-size: 12px;
    border-radius: 4px;
    margin-bottom: 10px;
    font-weight: 500;
    margin-left: auto;
    right: 0px;
    margin-top: 15px;
    margin-right: 15px;
    cursor: pointer;
  }

  .competition-content {
    position: relative;
    width: 90%;
    margin-top: 40px;
    max-width: 800px;

    p, h3 {
      text-align: left;
      line-height: 140%;
      margin: 0px;
      margin-bottom: 5px;
      padding: 0px;
    }

    p {
      font-size: 22px;
      @media (max-width: 900px) {
        font-size: 16px;
      }
    }

    h3 {
      font-size: 28px;
      margin-top: 30px;
      @media (max-width: 900px) {
        font-size: 20px;
      }
    }
  }

  video {
    width: 90%;
    max-width: 800px;
    height: 450px;
    margin-top: 30px;
    border-radius: 4px;
    background-color: #000;
    @media (max-width: 900px) {
      height: 350px;
    }

    @media (max-width: 600px) {
      height: 250px;
    }
  }

  .enter-comp-button {
    margin-top: 60px;
    @media (max-width: 900px) {
      width: 100%;
    }
  }

  .open-leaderboard-button {
    margin-left: 15px;
    @media (max-width: 900px) {
      margin-left: 0px;
      margin-top: 15px;
      width: 100%;
    }
  }

  .share-comp-link {
    position: relative;
    margin-top: 80px;
    width: 100%;
  
    .comp-link {
      position: relative;
      width: 90%;
      max-width: 450px;
      height: 40px;
      border: 1px solid #ddd;
      border-radius: 30px;
      margin: 0 auto;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 5px 20px;
      font-size: 14px;
  
      &:hover .tooltip {
        opacity: 1;
        visibility: visible;
      }
  
      .link-text {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
  
      .comp-link-icon {
        position: absolute;
        right: 0px;
        width: 60px;
        height: 40px;
        border-radius: 50%;
        background-image: url("../../assets/copy.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 40%;
        background-color: #fff;
      }
  
      .tooltip {
        position: absolute;
        bottom: 50px;
        left: 50%;
        transform: translateX(-50%);
        background-color: #333;
        color: #fff;
        padding: 5px 10px;
        border-radius: 4px;
        font-size: 12px;
        white-space: nowrap;
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.3s, visibility 0.3s;
  
        &::after {
          content: '';
          position: absolute;
          top: 100%;
          left: 50%;
          transform: translateX(-50%);
          border-width: 5px;
          border-style: solid;
          border-color: #333 transparent transparent transparent;
        }
      }
    }
  }
  
  

}