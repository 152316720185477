.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 1000;
  overflow-y: scroll;
  padding-top: 30px;
}

.popup-container {
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  position: relative;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  width: 100%;
  margin-bottom: 120px;
}

.popup-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.popup-content {
  margin-top: 20px;
}

.popup-video-drop-zone {
  width: 90%;
  height: 220px;
  background-color: #eee;
  position: relative;
  margin: 0 auto;
  border-radius: 4px;
  margin-bottom: 30px;
  cursor: pointer;
  text-align: center;
  border: 2px dashed #ccc;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    margin: 0;
    color: #999;
    max-width: 90%;
  }

  .file-input {
    display: none;
  }

  &:hover {
    border-color: #007bff;
  }

  &.active {
    border-color: #0056b3;
  }
}

form {
  position: relative;
  width: 90%;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  margin-bottom: 30px;
}

label {
  margin-bottom: 8px;
  font-weight: bold;
}

input, textarea {
  position: relative;
  width: 100%;
  padding: 8px 0px;
  margin-bottom: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  text-indent: 10px;
}

textarea {
  position: relative;
  display: inline-block;
  resize: none;
  height: 100px;
  font-family: "Roboto", sans-serif;
  padding: 10px;
  text-indent: 0px;
  box-sizing: border-box;
}

button[type="submit"] {
  position: relative;
  display: flex;
  padding: 0.5em 1em;
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 0.25em;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-left: auto;
  margin-top: 30px;
}

button[type="submit"]:hover {
  background-color: #0056b3;
}

.date-range-picker {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 0 auto;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;

  .date-picker-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    // width: 48%;

    label {
      margin-bottom: 8px;
      font-weight: bold;
    }

    .react-datepicker-wrapper {
      width: 100%;
    }

    .react-datepicker__input-container input {
      width: 100%;
      border: 1px solid #ccc;
      border-radius: 4px;
    }
  }
}
