.list-competitions {
  padding: 0;
  width: 100%;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;

  .competition-item {
    position: relative;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 15px;
    margin-bottom: 15px;
    background-color: #fff;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    width: calc(33.333% - 10px);
    height: 220px;
    box-sizing: border-box;

    .permission-scope {
      border: 1px solid #a4aab0;
      background-color: #fff;
      color: #a4aab0;
      padding: 5px 10px;
      text-align: center;
      font-size: 12px;
      border-radius: 30px;
      margin-bottom: 10px;
      font-weight: 600;
    }

    h3 {
      margin: 0px;
      margin-bottom: 10px;
      font-size: 20px;
      color: #000;
      text-align: left;
    }

    span {
      padding-bottom: 5px;
      color: #a4aab0;
      font-weight: 500;
    }

    ul {
      list-style-type: disc;
      padding-left: 20px;

      li {
        color: #666;
      }
    }

    .button-container {
      position: absolute;
      right: 0px;
      bottom: 0px;
      height: 70px;
      width: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
      padding: 10px;
      box-sizing: border-box;
      border-top: 1px solid #ddd;

      button {
        margin: 5px;
      }
    }
  }
}

@media (max-width: 1200px) {
  .list-competitions {
    flex-direction: column;

    .competition-item {
      width: 100%;
    }
  }
}
